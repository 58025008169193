// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      CENTRALVIEW: 'Dashboard',

      // Menu sections 
      CITY_HALL: 'City Hall ',
      ACCOUNTING: 'Accounting ',
      PORTAL: 'Citizen Portal',
      CONFIGURATOR: "Configurator ",

      // Settings
      SETTINGS: 'Settings ',
      COUNTRIES: 'Countries ',
      LANGUAGES: 'Languages ',
      DYNAMICREPORTS: "Dynamic Reports ",

      // System Security
      SECURITYSYSTEMS: "System Security ",
      USERS: "Users ",
      ROLES: "Roles ",

      //Notifications
      NOTIFICATIONS: "Notifications ",
      MESSAGES: "Messages ",

      // ERP
      ERP: "ERP ",
      CUSTOMERSVIEW: "Customers View ",
      SUPPLIERSVIEW: "Suppliers View ",
      STOCKITEMSVIEW: "Stock Item View ",

      // CONFIGURATOR
      ANSWERS: "Anwers",
      ORDERS: "Orders ",
      QUESTIONS: "Questions ",
      QUESTIONGROUPS: "Question Groups ",
      QUESTIONNAIRES: "Questionnaires ",
      WIZARD: "Wizard Configurator ",
      ANSWERGROUPS: "Answer Groups ",
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      },
    },
    FORMS: {
      SupportingDocuments:"Supporting documents",
      List: "List ",
      Cancel: "Cancel ",
      Save: "Save ",
      TotalRecords: "Total Records ",
      Loading: "Loading ",
      AdvanceSearch: "Advance Search ",
      Create: "Create ",
      Edit: "Edit ",
      Delete: "Delete ",
      TheFieldWasEnteredCorrect: "The field was entered correct ",
      TheFieldIsRequired: "The field is required ",
      ShouldHaveMoreSymbols: "Should have more symbols ",
      ShouldHaveMaximumSymbols: "Should have maximum symbols ",
      ShouldBeEmail: "Should be a valid email account",
      HasBeenAdded: "has been added ",
      HasBeenUpdated: "has been updated ",
      HasBeenDeleted: "has been deleted ",
      HasBeenEdited: "has been edited ",
      AreYouSureYouWantToDelete: "Are you sure you want to delete? ",
      ClearFilters: "Clear Filters ",
      Search: "Search ",
      General: "GENERAL ",
      Mail: "Mail ",
      Contact: "Contact ",
      Other: "Other ",
      Reset: "Reset ",
      Back: "Back ",
      Map: "Map ",
      Refresh: "Refresh ",
      CheckAll: "Check All ",
      UnCheckAll: "UnCheck All ",
      FirstParty: "FIRST SPOUSE",
      SecondParty: "SECOND SPOUSE",
      Witnesses: "Witnesses",
      SaveDraft: "Save Draft",
      
      AreYouSure: "Are you sure? ",
      YouWonotBeAbleToRevertThis: "You won't be able to revert ",
      Confirm: "Confirm ",
      Success: "Success ",     
      View: "View ",
      Reduction: "Reduction ",
      Increase: "Increase ",
      QueryParameters: "Query Parameters ",
    },
    ETC: {
      AccountNumber: "Account Number ",
      Active: "Active ",
      Address: "Address ",
      Amount: "Amount ",
      AmountPerc: "Amount % ",
      Area: "Area ",
      BirthDate: "Birth Date ",
      Block: "Block ",
      ChargeAllBeforeNewPeriod: "Charge All Before New Period ",
      Citizen: "Citizen ",
      Citizens: "Citizens ",
      Code: "Code ",
      Comments: "Comments",
      Company: "Company ",
      CompanyShareCapital: "Company Share Capital ",
      ContactPerson: "Contact Person ",
      Countries: "Countries ",
      Country: "Country ",
      CountryForReports: "Country For Reports ",
      CreditAmount: "Credit Amount ",
      Current: "Current ",
      Date: "Date ",
      DebitAmount: "Debit Amount ",
      Description: "Description",
      DirectorIDCard: "Director ID Card ",
      DirectorMailArea: "Director Mail Area ",
      DirectorMailPostalCode: "Director Mail Postal Code ",
      DirectorMailPremiseInfo: "Director Mail Premise Info ",
      DirectorMailRoadName: "Director Mail Road Name ",
      DirectorMailRoadNumber: "Director Mail Road Number ",
      DirectorName: "Director Name ",
      Email: "Email ",
      Website: "Website",
      WeddingAgencies: "Wedding Agencies ",
      WeddingAgency: "Wedding Agency ",
      AgencyCode: "Agency Code ",
      AgencyName: "Agency Name ",
      Embassies: "Embassies ",
      Embassy: "Embassy ",
      Eterodimotis: "Heterodimotis ",
      FathersName: "Father's Name ",
      Fax: "Fax ",
      FirstName: "First Name ",
      FlatNumber: "Flat Number ",
      FromDate: "From Date ",
      FullName: "Full Name ",
      HasMaxAmt: "Has maximum amount ",
      HasPercentPenalty: "Has Percent Penalty ",
      HasPolyDiscount: "More than 3 children disc. ",
      HasTaxFixedAmt: "Tax Fixed Amount ",
      HasTriteknosDiscount: "3 children disc. ",
      HasVat: "Has Vat ",
      IdCard: "Id Card ",
      IdCardCountry: "Id Card Country ",
      IdCardType: "Id Card Type ",
      Invoice: "Invoice ",
      InvoiceDescriptionType: "Invoice Type ",
      Invoices: "Invoices ",
      Kind: "Kind ",
      Language: "Language ",
      Languages: "Languages ",
      LastBankPayDate: "Last Bank Pay Date ",
      LastName: "Last Name ",
      LastPayDate: "Last Pay Date ",
      LineId: "Line Id ",
      MaritalStatus: "Marital Status ",
      MaritalStatusDate: "Marital Status Date ",
      Mobile: "Mobile ",
      MothersName: "Mother's Name ",
      Name: "Name ",
      NickName: "Nick Name ",
      Number: "Number ",
      NumberOfEmployees: "Number Of Employees ",
      OnlinePseudoId: "Online Pseudo Id ",
      OperationStopDate: "Operation Stop Date ",
      ParcelNumber: "Parcel Number ",
      Payer: "Payer ",
      PayerTaxes: "Payer Taxes ",
      PayerTax: "Payer Tax ",
      PenaltyImposed: "Penalty Imposed ",
      Period: "Period ",
      PeriodStatuses: "Period Statuses ",
      Periods: "Periods ",
      Phone: "Phone ",
      OfficePhone: "Office Phone ",
      RepresentaticeName: "Representatice Name",
      Plan: "Plan ",
      Polyteknos: "Having Many Children ",
      PolyteknosIdNumber: "Having Many Children Number ",
      PostRouteCode: "Post Route Code ",
      PostalCode: "Postal Code ",
      Premise: "Premise ",
      Premises: "Premises ",
      PremiseInfo: "Premise Info ",
      PremisesDetail: "Premises Detail ",
      PreviousPeriod: "Previous Period ",
      PropertySequenceNumber: "Property Sequence Number ",
      Prosecute: "Prosecute ",
      RoadName: "Road Name ",
      RoadNumber: "Road Number ",
      Sex: "Gender ",
      Sheet: "Sheet ",
      SiNumber: "SI Number ",
      SpousesName: "Spouse's Name ",
      Tax: "Tax ",
      TaxBasedOnPortion: "Tax Based on Portion ",
      TaxComments: "Tax Comments ",
      TaxMethod: "Tax Method ",
      TaxName: "Tax Name ",
      TaxSubCategory: "Tax Sub Category ",
      TaxType: "Tax Type ",
      Taxable: "Taxable ",
      Taxes: "Taxes ",
      ToDate: "To Date ",
      Transaction: "Transaction ",
      Transactions: "Transactions ",
      Triteknos: "Three Children ",
      TriteknosIdNumber: "Three Children Number ",
      Vat: "VAT ",
      VoteBookNumber: "Vote Book Number ",
      Year: "Year ",
      TempTaxed: "Temp Taxed ",
      Taxed: "Taxed ",
      Adder: "Adder ",
      Adders: "Adders ",
      TransactionJob: "Transaction Job ",
      TransactionJobs: "Transaction Jobs ",
      UserTransaction: "Είναι Συναλλαγή Χϝήστη ",
      FixedAmount: "Fixed Amount ",
      VatTransaction: "Vat Transaction ",
      ArrearsTransaction: "Arrears Transaction ",
      PenaultyTransaction: "Penaulty Transaction ",
      VatParentTransactionJob: "Vat Parent Transaction Job ",
      Credit: "Credit ",
      Debit: "Debit ",
      Field: "Field ",
      Type: "Type ",
      VatPercentage: "Vat Percentage (%)",
      Role: "Role ",
      Roles: "Roles ",
      User: "User ",
      Users: "Users ",
      UserName: "UserName ",
      Password: "Password ",
      Allowed: "Allowed ",
      Group: "Group ",
      UseAccountAndPasswordToContinue: "Use account and password to continue. ",
      Welcome: "Welcome",
      ForgotPassword: "Forgot Password ",
      SignIn: "Sign In ",
      Hi: "Hi ",
      UserProfile: "User Profile ",
      ForbiddenService: "Forbidden Service ",
      UnexpectedError: "Unexpected Error ",
      SomethingWentWrong: "Something Went Wrong... ",
      SpecialRights:"Special Rights ",
      Permission: "Permission ",
      Permissions: "Permissions ",
      ImportedData: "Imported Data ",
      SpatilReport: "Spatil Report ",
      RegistrationNumber: "Registration Number ",
      Share: "Share ",
      Owner: "Owner ",
      PremiseType: "Premise Type ",
      District: "District ",
      Road: "Road ",
      Village: "Village ",
      SheetsPlan: "Sheets Plan ",
      RoadSupplementaryNumber: "Road Supplementary Number ",
      CourtName: "Court Name ",
      PropertyBlockNumber: "Property Block Number ",
      Zone: "Zone ",
      ValuationWhole: "Valuation Whole ",
      PropertyExtent: "Property Extent ",
      ShareNominator: "Share Nominator ",
      ShareDenominator: "Share Denominator ",
      UnregisteredEntry: "Unregistered Entry ",
      Rented: "Rented ",
      Completed: "Completed ",
      TaxCategory: "Tax Category ",
      TaxCategories: "Tax Categories ",
      TaxSubCategories: "Tax Subcategories ",
      Detail: "Detail ",
      ParametricElements: "Parametric Elements ",
      BillingDetails: "Billing Details ",
      Select: "Select ",
      TaxationAddition: "Taxation Addition ",
      TaxationAdditionWithCategory: "Taxation Addition with Category ",
      Dashboard: "Citizen Dashboard",
      Applications: "Applications",
      Application: "Application",
      Modules: "Modules",
      Quicklinks: "Quick Links",
      SelectSubjectOfApplication: "Select Subject for your Application",
      ApplicationId: "Application Number",
      ApplicationType: "Application Type",
      CannotUpdateSubmitedForm : "Cannot Update Submited Application",
      Functions: "Functions ",
      Taxation: "Taxation ",
      Status: "Status ",
      Informations: "Information ",
      InTotal: "In Total ",
      Draft: "Draft ",
      Final: "Final ",
      TempTransactions: "Temp Transactions ",
      Pending: "Pending ",
      Exclusively: "Exclusively ",
      ThisEntryHasBeenTaxedBefore: "This entry has been taxed before ",
      Temporary: "Temporary ",
      Oops: "Oops ",
      WeddingReservation: " Wedding Reservation",
      WeddingReservations: " Wedding Reservations",
      Wedding: "Γάμος",
      Weddings: "Γάμοι",
      Licence: "Αδεια",
      AgencyRefNo: "Agency Reference No.",
      PassportNo: "Passport Number",
      Nationality: "Nationality",
      LicNo: "Licence Number",
      Age: "Age",
      PlaceOfBirth: "Birth Place",
      ContactAddress: "Contact Address",
      PermanentAddress: "Permanent Address",
      Profession: "Profession",
      PassportDateOfIssue: "Passport Issue Date",
      PassportCountry: "Passport Issue Country",
      DateOfArrival: "Arrival Date",
      BirthDateAbr: "Birthdate",
      FatherName: "Father Name",
      FatherProf: "Father's Profession",
      MotherName: "Mother Name",
      MotherProf: "Mother's Profession",
      NoOfGuests: "Number of Guests",
      VisitDate: "Reservation Date",
      NoticeDate: "Visit Date",
      WeddingOfficer: "Wedding Officer",
      CertificateOfficer: "Certifying officer",
      CertOfficerFee: "Certification fee",
      NoticeOfficer: "Notice Officer",
      InternalComments: "Internal Comments",
      FirstWitness: "First Witness",
      SecondWitness: "Seccond Witness",
      ThirdWitness: "Third witness",
      WeddingDateTime: "Wedding Date",
      CeremonyPlaceName: "Ceremony Place",
      FirstPartyName: "First Spouse Name",
      SecondPartyName: "Second Spouse Name",
      General : "General",
      Banks: "Banks ",
      Bank: "Bank ",
      Bic: "BIC",
      BankAccount: "Bank Account ",
      BankAccounts: "Bank Accounts ",
      PaymentMethods: "Payment Methods ",
      PaymentMethod: "Payment Method ",
      Payments: "Payments ",
      Payment: "Payment ",
      ChequeNo: "Cheque No ",
      ExpirationDate: "Expiration Date ",
      DynamicReports: "Dynamic Report ",
      DynamicReport: "Dynamic Report ",
      Instructions: "Instructions ",
      Query: "Query ",
      SaveOnHistory: "Save On History ",
      QueryParameters: "Query Parameters ",
      DisplayName: "Display Name ",
      ParameterName: "Parameter Name ",
      Value: "Value ",
      Execution: "Execution ",
      ExecutionName: "Execution Name ",
      ReservationStatus: "Reservation Status",
      ReservationStatuses: "Reservation Statuses",
      StatusKind: "Status Kind",
      StatusDescription: "Status Description",
      InApp: "InApp",
      Sms: "SMS",
      Push: "Push",
      Message: "Message ",
      Messages: "Messages ",
      Title: "Title ",
      Priority: "Priority ",
      Icon: "Icon ",
      Body: "Body ",
      Tags: "Tags ",
      Recipients: "Recipients ",
      Image: "Image ",
      YouMustSelectAnImage: "You must select an image ",
      OnlyImagesAreSupported: "Only images are supported ",
      ScheduleRunTime: "Schedule Run Time ",
      Incoming: "Incoming ",
      Outgoing: "Outgoing ",

      Business: "Business ",
      CustomerView: "Customer View ",
      CustomersView: "Customers View ",
      City: "City ",
      Balance: "Balance ",
      Discount: "Discount ",
      Manager: "Manager ",
      TaxOffice: "Tax Office ",
      VatDescription: "Vat Description ",
      PriceListDescription: "Price List Description ",
      PaymentTypeDescription: "Payment Type Description ",
      GeographicalAreaDescription: "Geographical Area Description ",
      SellerCode: "Seller Code ",
      SellerName: "Seller Name ",
      SupplierView: "Supplier View ",
      SuppliersView: "Suppliers View ",
      NetAmount: "Net Amount ",
      Series: "Series ",
      HelpCode: "Help Code ",
      MeasurementUnit: "Measurement Unit ",
      Category: "Category ",
      WholesaleRate: "% Wholesale Rate ",
      PurchasePrice: "Purchase Price ",
      WholesalePrice: "Wholesale Price ",
      RetailPrice: "Retail Price ",
      SupplierName: "Supplier Name ",
      StockItemsView: "Stock Items View ",
      Quantity: "Quantity ",
      VatAmount: "Vat Amount ",
      TotalAmount: "Total Amount ",
      Previous: "Previous ",
      Seller: "Seller ",
      SendEmail: "Send Email ",
      Cost: "Cost ",
      MappingReference: "Mapping Reference ",
      Answer: "Answer ",
      Answers: "Answers ",
      Question: "Question ",
      Questions: "Questions ",
      QuestionGroup: "Question Group ",
      QuestionGroups: "Question Groups ",
      Questionnaire: "Questionnaire ",
      Questionnaires: "Questionnaires ",
      Afm: "Vat ",
      Color: "Color ",
      Order: "Order ",
      Configurator: "Configurator ",
      NewRecord: "New Record ",
      Markup: "Markup ",
      DataType: "Data Type ",
      ProductCategories: "Product Categories ",
      ProductCategory: "Product Category ",
      AnswerGroups: "Answer Groups ",
      AnswerGroup: "Answer Group ",
      Exceptions: "Exceptions ",
      BulkAddition: "Bulk Addition ",
      ConnectionProblemTitle: "Connection Problem ",
      ConnectionProblemText: "The User Name or Password is incorrect. Try again.",

    }
  }
};
