import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ApiService } from '.';
import { ApiUsersService } from '../systemSecurity/users/api-users.service';

@Injectable({
  providedIn: 'root'
})

export class CommonFunctionsService
{
  constructor(
    private apiService: ApiService,
    private apiUsersService: ApiUsersService,
    public translateService: TranslateService,

  ) { };

  // async updateAbility()
  // {
  //   return new Promise((resolve, reject) =>
  //   {
  //     var token = this.apiService.getAuthFromLocalStorage();

  //     if (this.ability.rules.length === 0 && token)
  //     {
  //       this.apiUsersService.getEffectiveUserPermissions().toPromise().then((data) =>
  //       {
  //         const { can, rules } = new AbilityBuilder(Ability);

  //         let permissions: any[] = data;

  //         Object.keys(permissions).map((key, index) =>
  //         {
  //           if (permissions[key]) can("do", key);
  //         });

  //         this.ability.update(rules);

  //         resolve(this.ability.rules)
  //       }).catch(error =>
  //       {
  //         console.log("Cannot Update Ability. Logging out.")
  //         reject()
  //       });
  //     } else
  //     {
  //       resolve(this.ability.rules)
  //     }
  //   });
  // }

  deAccent(str: string)
  {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  }

  CalculateAge(dateOfBirth: any): number
  {
    try
    {
      let age = moment().diff(dateOfBirth, 'year', false);
      return age;
    } catch (error)
    {
      return 0;
    }
  }
}