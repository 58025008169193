export class AuthModel
{
  authToken: string;
  refreshToken: string;
  expiresIn: Date;
  
  setAuth(auth: any)
  {
    this.authToken = auth.authToken;
    this.refreshToken = auth.refreshToken;
    this.expiresIn = auth.expiresIn;
  }
}

export class AuthCustomModel
{
  access_token: string;
  refresh_token: string;
  expires_in: Date;
}