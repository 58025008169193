import { ChangeDetectorRef, Component, TemplateRef } from '@angular/core';
import { NotificationService } from '../../portal/_services/notification.service';

@Component({
  selector: 'app-toasts',
  template: `
    <ngb-toast
      *ngFor="let toast of notificationService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="notificationService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  `,
  host: { '[class.ngb-toasts]': 'true' }
})
export class ToastsContainer
{
  constructor(
    public notificationService: NotificationService,
    private chRef: ChangeDetectorRef)
  {
    const _this = this;

    setInterval(() =>
    {
      if (_this.notificationService.toasts.length > 0)
      {
        _this.chRef.detectChanges();
      }
    }, 500);
  }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
