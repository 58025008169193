import { ChangeDetectorRef, Injectable, TemplateRef } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class NotificationService
{
  toasts: any[] = [];

  constructor()
  {
  }

  show(textOrTpl: string | TemplateRef<any>, options: any = {})
  {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast)
  {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
 
  showSuccess(data)
  {
    this.show(data.title, { classname: 'bg-success text-light', delay: data.timeout });
  }

  showDanger(data)
  {
    this.show(data.title + ' ' + data.content, { classname: 'bg-danger text-light', delay: data.timeout });
  }
}
