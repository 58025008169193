import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';

const API_USERS_URL = `${ environment.apiUrl }`;
const API_TOKEN_URL = `${ environment.tokenUrl }`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService
{
  constructor(private http: HttpClient) { }

  //* public methods

  //! Login 
  login(email: string, password: string): Observable<any>
  {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };

    let body = new URLSearchParams();

    var grant_type = "password";
    var scope = "offline_access";

    body.set('grant_type', grant_type);
    body.set('username', email);
    body.set('password', password);
    body.set('scope', scope);

    return this.http.post<AuthModel>(`${ API_TOKEN_URL }/token`, body.toString(), httpOptions);
  }

  //! CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel>
  {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  //! Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean>
  {
    return this.http.post<boolean>(`${ API_USERS_URL }/forgot-password`, {
      email,
    });
  }

  //! Get User By Token
  getUserByToken(token): Observable<UserModel>
  {
    const httpHeaders = new HttpHeaders({ Authorization: `Bearer ${ token }` });

    return this.http.get<any>(`${ API_TOKEN_URL }/me`, { headers: httpHeaders, });
  }
}
