import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, forkJoin, throwError, NEVER } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AddressModel } from '../_models/address.model';
import { SocialNetworksModel } from '../_models/social-networks.model';
import { AuthModel, AuthCustomModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy
{
  // private fields
  private unsubscribe: Subscription[] = [];
  private authLocalStorageToken = `${ environment.appVersion }-${ environment.USERDATA_KEY }`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  currentUserSubject: BehaviorSubject<UserModel>;
  effectiveUserPermissions: BehaviorSubject<any>;

  user: any;

  get currentUserValue(): UserModel
  {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel)
  {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private httpClient: HttpClient,
  )
  {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);

    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();

    this.unsubscribe.push(subscr);
  }

  //* Public methods

  //! Login
  login(email: string, password: string): Observable<UserModel>
  {
    this.isLoadingSubject.next(true);

    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthCustomModel) =>
      {
        var authModel = new AuthModel();

        authModel.authToken = auth.access_token;
        authModel.expiresIn = auth.expires_in;
        authModel.refreshToken = auth.refresh_token;

        const result = this.setAuthFromLocalStorage(authModel);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) =>
      {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() =>
      {
        //this.getEffectiveUserPermissions();
        this.isLoadingSubject.next(false);
      })
    );
  }

  //! Logout
  logout()
  {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel>
  {
    const auth = this.getAuthFromLocalStorage();

    if (!auth || !auth.authToken)
    {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);

    var customUserModel = new UserModel();
    var customAddressModel = new AddressModel();
    var customSocialNetworksModel = new SocialNetworksModel();

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${ auth.authToken }`,
      })
    };

    this.httpClient.get(environment.apiUrl + "/user/currentuser", httpOptions)
      .subscribe((response) =>
      {
        this.user = response;

        const firstName = this.user.firstName ? this.user.firstName : "";
        const lastName = this.user.lastName ? this.user.lastName : "";
        const fullname = firstName === "" ? lastName : firstName + " " + lastName;

        customUserModel.id = this.user.Id;
        customUserModel.username = this.user.username;
        customUserModel.password = null;

        customUserModel.firstname = this.user.firstName;
        customUserModel.lastname = this.user.lastName;
        customUserModel.fullname = fullname;

        customUserModel.email = this.user.email;
        customUserModel.pic = './assets/media/users/default.jpg';
        customUserModel.roles = this.user.roles;
        customUserModel.occupation = "";
        customUserModel.companyName = "";
        customUserModel.phone = this.user.phoneNumber;

        customUserModel.address = customAddressModel;
        customUserModel.socialNetworks = customSocialNetworksModel;

        customUserModel.language = 'en';
        customUserModel.authToken = auth.authToken;
        customUserModel.refreshToken = auth.refreshToken;
      });

    this.currentUserSubject = new BehaviorSubject<UserModel>(customUserModel);

    return of(customUserModel);
  }

  //! Need create new user then login
  registration(user: UserModel): Observable<any>
  {
    this.isLoadingSubject.next(true);

    return this.authHttpService.createUser(user).pipe(
      map(() =>
      {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) =>
      {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean>
  {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  //* Private methods

  private setAuthFromLocalStorage(auth: AuthModel): boolean
  {
    if (auth && auth.authToken)
    {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel
  {
    try
    {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error)
    {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy()
  {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
