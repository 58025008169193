import { ApiService } from '../../../modules/portal/_services/api.service';
import { NotificationService } from '../../../modules/portal/_services/notification.service';

export const services = [
  ApiService,
  NotificationService
];

// Models
export * from './models/table.model';
export * from './models/sort.model';
export * from './models/paginator.model';
export * from './models/grouping.model';
export * from './models/search.model';
export * from './models/filter.model';
export { BaseModel } from './models/base.model';

// Directives

// Services
export * from './services/table.service';
export * from './services/table.extended.service';
export * from '../../../modules/portal/_services/api.service';
export * from '../../../modules/portal/_services/notification.service';

// Module
export { CRUDTableModule } from './crud-table.module';
