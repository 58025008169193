import { Injectable } from "@angular/core";
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { NotificationService } from './notification.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class HelperFunctionsService
{
  constructor(
    private notificationService: NotificationService,
    public translateService: TranslateService,
  ) { };

  public displayNotificationErrorAlert(error: any)
  {
    if (error)
    {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) =>
        {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

      try
      {
        if (Array.isArray(error.error))
        {
          for (var key in error.error)
          {
            Toast.fire({ icon: 'error', title: error.error[key].title, text: error.error[key].description });
          }
        } else
        {
          Toast.fire({ icon: 'error', title: this.translateService.instant("ETC.UnexpectedError"), text: this.translateService.instant("ETC.SomethingWentWrong") });
        }
      } catch {
        Toast.fire({ icon: 'error', title: this.translateService.instant("ETC.UnexpectedError"), text: this.translateService.instant("ETC.SomethingWentWrong") });
      }
    }
  }

  public displayNotificationSuccessAlert(successTitle: string)
  {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) =>
      {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    Toast.fire({ icon: 'success', title: successTitle });
  }

  public displayNotificationForbiddenAlert()
  {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) =>
      {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    Toast.fire({ icon: 'error', title: this.translateService.instant("ETC.ForbiddenService") });
  }

  formMapper(form: FormGroup, data: any, mappings: any = {}, nestedPaths?: any, exclusions?: Array<string>)
  {
    for (var formKey in form.controls)
    {
      //Check for exclusions
      if (exclusions != undefined && exclusions.includes(formKey))
      {
        continue;
      }

      if (nestedPaths != undefined)
      {
        let nestedPath = formKey in nestedPaths ? nestedPaths[formKey] : null;

        if (nestedPath != null)
        {
          //advanced mapping for when the data property is within an object (depth level == 1)
          let objectName = nestedPath.obj;
          let property = nestedPath.prop;
          let finalValue = this.isPropertyNullOrEmpty(data[objectName], property);
          form.controls[formKey].setValue(finalValue == null ? '' : finalValue);
          continue;
        }
      }

      //normal property to property mapping
      let dataMappingKey = formKey in mappings ? mappings[formKey] : formKey
      form.controls[formKey].setValue(data[dataMappingKey] == null ? '' : data[dataMappingKey]);
    }
  }

  formReset(form: FormGroup)
  {
    for (var formKey in form.controls)
    {
      if (typeof form.controls[formKey].value === 'boolean')
      {
        form.controls[formKey].setValue(false);
      }
      else
      {
        form.controls[formKey].setValue('');
      }
    }
  }

  //Concept: Instead of performing a turnary for each control, pass it directly to the function.
  // i.e If i want to check for clientDocument.document.name, i will perform isNullOrEmpty(clientDocument.document, "name")
  public isPropertyNullOrEmpty(field: any, property: any)
  {
    return field && field[property] ? field[property] : ''
  }

  public convertDateTimeToLocal(date: Date): string
  {
    return moment.utc(date).local().format('DD/MM/YYYY HH:mm');
  }

  public convertDateToLocal(date: Date): string
  {
    return moment.utc(date).local().format('DD/MM/YYYY');
  }
}
